import { isEditMode } from '~/utils/content/magnolia';
import { imageResizerPath } from '~/utils/helper/magnolia';
import useMagnoliaContent from '~/composables/useMagnoliaContent';
import { parseHTMLScript } from '~/utils/helper/html';

export default function () {
    const { currentLanguage, availableLanguages } = useMagnoliaLanguage();
    const { content, canonical, robots } = useMagnoliaContent();
    const {
        public: { baseUrl },
    } = useRuntimeConfig();

    const { marketSetup, marketSetupAdmin } = useMarketSetup();
    const title = content?.value?.title ?? marketSetup?.fallbackTitle;
    const ogTitle = content.value?.ogTitle ?? title;
    const ogImagePath = imageResizerPath(content.value?.ogImage, 1200, 630);
    const ogType = content.value?.ogType;
    const description = content?.value?.description ?? marketSetup?.fallbackDescription;
    const route = useRoute();
    const routeWithoutLanguage = route.path.replace(`/${currentLanguage.value?.toLowerCase()}`, '');

    const hasMultipleLanguages = availableLanguages.value.length > 1;
    const alternateLinks = availableLanguages.value?.map(language => {
        return {
            rel: 'alternate',
            hreflang: language.replace('_', '-'),
            href: `${baseUrl}${hasMultipleLanguages ? `/${language.toLowerCase()}` : ``}${routeWithoutLanguage}`,
        };
    });

    const dir = marketSetupAdmin.value?.textDirection === 'rightToLeft' ? 'rtl' : 'ltr';

    const cmpSnippet = parseHTMLScript(marketSetupAdmin.value?.cmpSnippet ?? '') ?? {};

    useServerHead({
        script: [
            !isEditMode() &&
                cmpSnippet.hasScript && {
                    ...(cmpSnippet?.attributes ?? {}),
                    ...(cmpSnippet?.textContent ? { innerHTML: cmpSnippet?.textContent } : {}),
                },
        ],
    });

    useHead({
        title,
        htmlAttrs: { lang: currentLanguage.value.replace('_', '-'), dir },
        meta: [
            ...(ogTitle ? [{ name: 'og:title', content: ogTitle }] : []),
            ...(ogType ? [{ name: 'og:type', content: ogType }] : []),
            ...(ogImagePath ? [{ name: 'og:image', content: ogImagePath }] : []),
            ...(description ? [{ name: 'description', content: description }] : []),
            ...(description ? [{ name: 'og:description', content: description }] : []),
            ...(robots.value ? [{ name: 'robots', content: robots.value }] : []),
        ],
        link: [
            ...alternateLinks,
            ...(canonical.value
                ? [
                      {
                          rel: 'canonical',
                          href: canonical.value ? `${baseUrl}${canonical.value}` : false,
                      },
                  ]
                : []),
        ],
    });

    if (process.client) {
        usePageMetaTracking(title, content?.value?.['mgnl:template']);
    }
}
